$row-height: 48px;
$header-height: 48px;
$font-size: 14px;
$secondary-font-size: 14px;
//$cell-horizontal-padding: 20px;

@import "~@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "~@ag-grid-community/core/dist/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark-mixin.scss";



.ag-body-viewport {
  background-color: #293742 !important;
}

.ag-row {
  border: 0 solid transparent !important;
}

.ag-header {
  border-bottom: 1px solid #283540 !important;
}

.ag-header,
.ag-menu {
  background-color: #30404d !important;
}

.ag-filter-apply-panel > button {
  background-color: #252b2e;
}

.ag-root-wrapper {
  border: solid 0 transparent !important;
}

.ag-theme-balham-dark {
  font-size: 14px;
}

.ag-floating-bottom,
.ag-floating-bottom > *,
.ag-floating-bottom > * > * {
  transition: height 0.3s ease-out, min-height 0.3s ease-out;
}

:root {
  --ag-background-color: #24303b;
}
