@import "~normalize.css/normalize.css";
@import "blueprintjs-styles.css";
@import "~@ag-grid-community/core/dist/styles/ag-grid.css";
@import "~@ag-grid-community/core/dist/styles/ag-theme-balham-dark.css";


.custom-tooltip {
  position: absolute;
  z-index: 1000000;
  overflow: visible;
  /*pointer-events: none;*/
  transition: opacity 1s;
  opacity: 1;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #293742 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header.header0.home-page-wrapper > div.home-page {
  max-width: 1800px;
}

.ant-modal-header {
  border-bottom: 0 !important;
}

.ant-modal-content,
.ant-modal-content .ant-modal-header,
.ant-layout,
.ag-body-viewport {
  background-color: #293742 !important;
}

.ant-modal-content .ant-statistic-title,
.ant-modal-content .ant-progress-text,
.ant-modal-content .ant-modal-close-x,
.ant-modal-content .ant-modal-title {
  color: white !important;
}

.ag-row {
  border: 0 solid transparent !important;
}

.ag-header,
.ag-menu {
  background-color: #30404d !important;
}

.ant-progress-circle-trail {
  stroke: #00192b !important;
}

.ag-filter-apply-panel > button {
  background-color: #252b2e;
}

.ant-message-notice-content {
  background-color: #30404d !important;
  color: white !important;
}

.cell-wrap-text {
  white-space: normal !important;
}

.ag-pivot-off .ag-header-group-cell {
  font-size: 50px;
  color: red;
}

.ag-pivot-on .ag-header-group-cell {
  font-size: 25px;
  color: green;
}

.ag-cell-label-container {
  /* Necessary to allow for text to grow vertically */
  height: 100%;
}

.ag-header-cell-label {
  /* Necessary to allow for text to grow vertically */
  height: 100%;
  padding: 0 !important;
}
.ag-header-cell--preformatted .ag-header-cell-label .ag-header-cell-text {
  white-space: pre-line;
  text-align: center;
}
.ag-header-cell-label .ag-header-cell-text {
  white-space: normal;
}

.ag-cell:not(.ellipses-tooltip-cell) {
  white-space: normal !important;
  line-height: 1.4 !important;
}
.ellipses-tooltip-cell {
  line-height: 1.4 !important;
}


.ag-react-container:not(.custom-tooltip),
.ag-cell.react-rendered-cell .ag-cell-value[role="presentation"] {
  width: 100%;
}

.ag-cell.ag-numeric-cell.react-rendered-cell .ag-cell-wrapper[role="presentation"] {
  justify-content: flex-end;
}

.clickable-tag-icon:hover {
  cursor: pointer;
}

.ag-cell.intent-success {
  color: #a1f66d;
}
.ag-cell.intent-none {
  color: white !important;
}

.line-clamp-2 .ag-react-container .line-clamp-target {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
